import { FirebaseFirestore, PageType } from '@innedit/innedit';
import React, { FC, useEffect, useState } from 'react';

import ListItem from '../../../../../CMS/containers/Boutique/components/new/Item';
import { ListItemProps } from '../../../../../CMS/containers/Boutique/components/new/props';

const Item: FC<ListItemProps<PageType>> = ({
  className,
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] =
    useState<FirebaseFirestore.DocumentSnapshot<PageType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  if (!doc.exists) {
    return <div>La page n&apos;existe pas</div>;
  }

  const data = doc.data() as PageType;

  return (
    <ListItem
      className={className}
      contentClassName="flex-auto flex flex-row space-x-3"
      displayActionId={false}
      doc={doc}
      index={index}
      onClick={onClick}
    >
      <strong className="client">{data.name}</strong>
      <span>{data.pathname}</span>
    </ListItem>
  );
};

export default Item;
