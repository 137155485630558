import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Backoffice from '../../core/sysext/CMS/containers/Backoffice';
import PageForm from '../../core/sysext/Website/containers/Page/components/Form';
import PageList from '../../core/sysext/Website/containers/Page/components/List';

const PagesPage: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;

  return (
    <Backoffice location={location}>
      {star ? <PageForm docId={star} {...props} /> : <PageList {...props} />}
    </Backoffice>
  );
};

export default PagesPage;
